
export const AppMenus = {

  navbarTopRight: [],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      "to": "/home",
      "label": "Home",
      "icon": "pi pi-home",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/user",
      "label": "Members",
      "icon": "pi pi-user",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/candidate",
      "label": "Candidate",
      "icon": "pi pi-user-plus",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/election",
      "label": "Elections",
      "icon": "pi pi-thumbs-up-fill",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/results",
      "label": "Election Results",
      "icon": "pi pi-chart-pie",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/news",
      "label": "News",
      "icon": "pi pi-globe",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/newscategory",
      "label": "News Category",
      "icon": "pi pi-list",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/admin",
      "label": "Admin",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/role",
      "label": "Role",
      "icon": "pi pi-sitemap",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/support",
      "label": "Support",
      "icon": "pi pi-wrench",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/votecategory",
      "label": "Vote Category",
      "icon": "pi pi-th-large",
      "iconcolor": "",
      "target": "",

    },
    {
      "to": "/votesubcategory",
      "label": "Vote Subcategory",
      "icon": "pi pi-tags",
      "iconcolor": "",
      "target": "",

    }
  ],
  category: [
    { value: "text", label: "Text" },
    { value: "video", label: "Video" }
  ],
  status: [
    { value: "Closed", label: "Closed" },
    { value: "Open", label: "Open" }
  ],
  status2: [
    { value: "Inactive", label: "Inactive" },
    { value: "Active", label: "Active" }
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}