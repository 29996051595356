
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//admin routes
			{
				path: '/admin/:fieldName?/:fieldValue?',
				name: 'adminlist',
				component: () => import('./pages/admin/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/admin/view/:id', 
				name: 'adminview', 
				component: () => import('./pages/admin/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'adminuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'adminaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'adminaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/admin/add', 
				name: 'adminadd', 
				component: () => import('./pages/admin/add.vue'), 
				props: true
			},
	
			{ 
				path: '/admin/edit/:id', 
				name: 'adminedit', 
				component: () => import('./pages/admin/edit.vue'), 
				props: true
			},
		

//candidate routes
			{
				path: '/candidate/:fieldName?/:fieldValue?',
				name: 'candidatelist',
				component: () => import('./pages/candidate/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/candidate/view/:id', 
				name: 'candidateview', 
				component: () => import('./pages/candidate/view.vue'), 
				props: true
			},
		
			{ 
				path: '/candidate/add', 
				name: 'candidateadd', 
				component: () => import('./pages/candidate/add.vue'), 
				props: true
			},
	
			{ 
				path: '/candidate/edit/:id', 
				name: 'candidateedit', 
				component: () => import('./pages/candidate/edit.vue'), 
				props: true
			},
		

//election routes
			{
				path: '/election/:fieldName?/:fieldValue?',
				name: 'electionlist',
				component: () => import('./pages/election/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/election/view/:id', 
				name: 'electionview', 
				component: () => import('./pages/election/view.vue'), 
				props: true
			},
		
			{ 
				path: '/election/add', 
				name: 'electionadd', 
				component: () => import('./pages/election/add.vue'), 
				props: true
			},
	
			{ 
				path: '/election/edit/:id', 
				name: 'electionedit', 
				component: () => import('./pages/election/edit.vue'), 
				props: true
			},
		

//news routes
			{
				path: '/news/:fieldName?/:fieldValue?',
				name: 'newslist',
				component: () => import('./pages/news/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/news/view/:id', 
				name: 'newsview', 
				component: () => import('./pages/news/view.vue'), 
				props: true
			},
		
			{ 
				path: '/news/add', 
				name: 'newsadd', 
				component: () => import('./pages/news/add.vue'), 
				props: true
			},
	
			{ 
				path: '/news/edit/:id', 
				name: 'newsedit', 
				component: () => import('./pages/news/edit.vue'), 
				props: true
			},
		

//newscategory routes
			{
				path: '/newscategory/:fieldName?/:fieldValue?',
				name: 'newscategorylist',
				component: () => import('./pages/newscategory/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/newscategory/view/:id', 
				name: 'newscategoryview', 
				component: () => import('./pages/newscategory/view.vue'), 
				props: true
			},
		
			{ 
				path: '/newscategory/add', 
				name: 'newscategoryadd', 
				component: () => import('./pages/newscategory/add.vue'), 
				props: true
			},
	
			{ 
				path: '/newscategory/edit/:id', 
				name: 'newscategoryedit', 
				component: () => import('./pages/newscategory/edit.vue'), 
				props: true
			},
		

//results routes
			{
				path: '/results/:fieldName?/:fieldValue?',
				name: 'resultslist',
				component: () => import('./pages/results/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//results routes

//results routes

//role routes
			{
				path: '/role/:fieldName?/:fieldValue?',
				name: 'rolelist',
				component: () => import('./pages/role/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/role/view/:id', 
				name: 'roleview', 
				component: () => import('./pages/role/view.vue'), 
				props: true
			},
		
			{ 
				path: '/role/add', 
				name: 'roleadd', 
				component: () => import('./pages/role/add.vue'), 
				props: true
			},
	
			{ 
				path: '/role/edit/:id', 
				name: 'roleedit', 
				component: () => import('./pages/role/edit.vue'), 
				props: true
			},
		

//support routes
			{
				path: '/support/:fieldName?/:fieldValue?',
				name: 'supportlist',
				component: () => import('./pages/support/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/support/view/:id', 
				name: 'supportview', 
				component: () => import('./pages/support/view.vue'), 
				props: true
			},
		
			{ 
				path: '/support/add', 
				name: 'supportadd', 
				component: () => import('./pages/support/add.vue'), 
				props: true
			},
	
			{ 
				path: '/support/edit/:id', 
				name: 'supportedit', 
				component: () => import('./pages/support/edit.vue'), 
				props: true
			},
		

//user routes
			{
				path: '/user/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('./pages/user/view.vue'), 
				props: true
			},
		
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('./pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('./pages/user/edit.vue'), 
				props: true
			},
		

//vote routes
			{
				path: '/vote/:fieldName?/:fieldValue?',
				name: 'votelist',
				component: () => import('./pages/vote/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vote/view/:id', 
				name: 'voteview', 
				component: () => import('./pages/vote/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vote/add', 
				name: 'voteadd', 
				component: () => import('./pages/vote/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vote/edit/:id', 
				name: 'voteedit', 
				component: () => import('./pages/vote/edit.vue'), 
				props: true
			},
		

//votecategory routes
			{
				path: '/votecategory/:fieldName?/:fieldValue?',
				name: 'votecategorylist',
				component: () => import('./pages/votecategory/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/votecategory/view/:id', 
				name: 'votecategoryview', 
				component: () => import('./pages/votecategory/view.vue'), 
				props: true
			},
		
			{ 
				path: '/votecategory/add', 
				name: 'votecategoryadd', 
				component: () => import('./pages/votecategory/add.vue'), 
				props: true
			},
	
			{ 
				path: '/votecategory/edit/:id', 
				name: 'votecategoryedit', 
				component: () => import('./pages/votecategory/edit.vue'), 
				props: true
			},
		

//votesubcategory routes
			{
				path: '/votesubcategory/:fieldName?/:fieldValue?',
				name: 'votesubcategorylist',
				component: () => import('./pages/votesubcategory/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/votesubcategory/view/:id', 
				name: 'votesubcategoryview', 
				component: () => import('./pages/votesubcategory/view.vue'), 
				props: true
			},
		
			{ 
				path: '/votesubcategory/add', 
				name: 'votesubcategoryadd', 
				component: () => import('./pages/votesubcategory/add.vue'), 
				props: true
			},
	
			{ 
				path: '/votesubcategory/edit/:id', 
				name: 'votesubcategoryedit', 
				component: () => import('./pages/votesubcategory/edit.vue'), 
				props: true
			},
		

//results routes

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
		{ 
			path: '/index/accountcreated', 
			name: 'accountcreated', 
			component: () => import('./pages/index/accountcreated.vue'), 
			props: true
		},
		{
			path: '/index/accountpending', 
			name: 'accountpending', 
			component: () => import('./pages/index/accountpending.vue'), 
			props: true
		},
		{ 
			path: '/index/accountblocked',
			name: 'accountblocked', 
			component: () => import('./pages/index/accountblocked.vue'), 
			props: true
		},

	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
